/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { getRoute } from '../../helpers'
import { CardOutline, Spinner } from '../../components'
import './how-to-claim-styles.scss'

const List = ({ t, data }) => {
  console.log("🚀 ~ List claim ~ data:", data)
  const { i18n } = useTranslation()
  let list
  const listItem = data.sub_category
    ? _.get(data, 'sub_category')
    : _.get(data, 'products')
  if (_.get(data, 'sub_category')) {
    list = _.map(listItem, (item, idx) => (
      <div className="cardList__continer" key={idx}>
        <span>{item.name}</span>
        <ul>
          {_.map(item.products, (pro) => (
            <li>
              <Link to={`/claim/${pro.slug}`}>{pro.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    ))
  } else {
    list = (
      <div className="cardList__continer">
        <ul>
          {_.map(listItem, (pro, idx) => (
            <li key={idx}> 
              {/* <Link to={`/claim/${pro.slug}`}>{pro.name}</Link> */}
              <Link to={getRoute({
                    locale: i18n.language,
                    route: `/claim/${pro.slug}`,
                  })}>
                    {pro.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return list
}

const HowToClaimView = ({ loading, data }) => {
  const { t, i18n } = useTranslation()

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="container-fluid">
      <div className="container-xxl">
        <div className="howToClaim__head">
          <h1 className="page-title">{t('howToClaim.title')}</h1>
          <div
            className="rich-text howToClaim__head__description"
            dangerouslySetInnerHTML={{ __html: _.get(data, 'subtitle') }}
          />
        </div>
        <div className="howToClaim__cards row">
          {_.map(_.get(data, 'list'), (item) => (
            <div
              className="col-sm-3 col-12 howToClaim__card"
              key={_.get(item, 'title')}
            >
              <CardOutline withoutImg={true} title={_.get(item, 'title')}>
                
                <div>
                  <List i18n={i18n} data={item} />
                </div>
              </CardOutline>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HowToClaimView
